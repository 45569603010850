.role-dropdown.role-dropdown.ui.inline.dropdown>.text,
.role-dropdown.ui.dropdown>.dropdown.icon {
    font-weight: normal;
    font-size: 15px;
    color: #2185d0;
}

.role-dropdown.ui.dropdown .menu .selected.item,
.role-dropdown.ui.dropdown.selected,
.role-dropdown.ui.dropdown .menu>.item {
    font-weight: normal;
    font-size: 15px;
    color: #000;
}

.ui.empty.circular.label {
    position: relative;
    top: -2px;
    width: 6px !important;
    height: 6px !important;
    padding: 0px !important;
}

.role-status.blue::after {
    background-color: #2185d0;
}

.role-status.red::after {
    background-color: #fbbd08;
}

.role-status::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 186px;
    margin: auto;
    z-index: 99;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.MuiTreeItem-iconContainer {
    margin-right: 0 !important;
}
