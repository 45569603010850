@import '~/node_modules/bootstrap/dist/css/bootstrap.css';

body {
	background: #f1f1f1;
	padding: 0;
	font-size: 16px;
}

.no-gutter {
  padding: 0;
}